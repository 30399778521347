#centerpiece2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 744px;
    height: 297px;
    flex-shrink: 0;
    margin: 160px auto 0;
    text-align: center;
  }
  
 .projects-description-container {
    width: 90%;
    max-width: 1000px;
    font-size: 24px;
    margin: 20px auto; /* Creates space between the header and paragraph */
    padding: 20px;
    box-sizing: border-box;
}

.projects-description {
    font-family: 'EB Garamond', serif;
    font-size: 25px;
    color: #A30000;
    line-height: 1.5; /* Improve readability */
    text-align: center; /* Optional */
}

  .main-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }
  /* Styling the button container */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between buttons */
  margin-top: 40px;
}

/* Collapsible button styling */
.collapsible-button {
  width: 1200px;
  height: 100px;
  background-color:#BC4749;
  color: white;
  font-family: 'EB Garamond', serif; 
  cursor: pointer;
  font-size: 24px;
  transition: height 0.3s ease, background-color 0.5s ease;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: none;
}

/* Expanded state with color transition */
.collapsible-button.expanded {
  height: 800px;
  background-color: #A30000; /* New color when expanded */
}

/* Button cover content remains at the top */
.button-cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px; /* Keeps this section fixed at 100px */
  padding: 0 20px;
  position: absolute; /* Fix it to the top */
  top: 0;
  left: 0;
  z-index: 1; /* Keep it on top */
}
/* Left-aligned text */
.resume-text {
  font-size: 35px;
  margin-left: 20px;
  font-family: 'EB Garamond', serif;
}

/* Right-aligned ellipsis with image */
.ellipsis {
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}

.recruiter-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Collapsible content with text and PDF 100px below the button cover */
.text-pdf-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 100px; /* Ensures the content appears below the button */
}

.unique-text {
  flex: 1;
  margin: 30px;
  color: white;
  margin-top: 20px;
  font-size: 22px;
  font-family: 'EB Garamond', serif;
  text-align: left;
}

.pdf-viewer-container {
  flex: 1;
  position: relative;
}

.resume-viewer {
  border: none;
  margin-right: 20px;
  margin-top: 20px;
}

/* Invisible download button positioned over the entire PDF */
.invisible-download-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Make the button invisible */
  cursor: pointer;
}
/* New Contact button styles */
.contract-button-container {
    display: flex;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 50px; /* Add margin to push it above the bottom bar */
  }
  
  .contract-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 900px; /* Make the button wider */
    height: 130px; /* Keep height taller */
    font-size: 30px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color: #BC4749; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #BC4749; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  

.contract-button:hover {
    background-color: #3A7243; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#3A7243;
}
