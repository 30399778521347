#centerpiece2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 744px;
    height: 297px;
    flex-shrink: 0;
    margin: 160px auto 0;
    text-align: center;
    margin-bottom: 50px;
  }
  
 .projects-description-container {
    width: 90%;
    max-width: 1000px;
    font-size: 24px;
    margin: 20px auto; /* Creates space between the header and paragraph */
    padding: 0px;
    box-sizing: border-box;
}
  .bottom-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px; /* Space between the buttons */
    flex-wrap: wrap;
  }
  
  /* Friends Button */
  .friends1-nav-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 400px; /* Make the button wider */
    height: 130px; /* Keep height taller */
    font-size: 30px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color: #0E0D0D; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #0E0D0D; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .friends1-nav-button:hover {
    background-color: #D5AC4E; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#D5AC4E;
  }
  
  /* Recruiters Button */
  .social-nav-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 400px; /* Make the button wider */
    height: 130px; /* Keep height taller */
    font-size: 30px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color: #0E0D0D; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #0E0D0D; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .social-nav-button:hover {
    background-color: #FFF; /* Change background on hover */
    color: black; /* Text color change on hover */
    border:#FFF;
  }
 
    .Philcontent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 40px auto;
    max-width: 1200px;
    width: 100%;
  }
  
  .Phil-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: 10px; /* Adjust the gap between Misc content and button */
  }
  
  .Phil-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    text-decoration: none;
    font-family: 'EB Garamond', serif;
    font-size: 20px;
    border-radius: 20px;
    transition: transform 0.3s, background-color 0.3s;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
  }
  
  .Phil-button:hover {
    background-color: white;
    color: black;
    transform: translateY(-10px);
  }
  
  .main-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }
  