/* Common Small Button Styles */
.small-btn, .small-btn2, .small-btn3 {
  height: 548px;
  flex-shrink: 0;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease; /* For smooth hover */
  border: none;
  outline: none;
}

/* Hover Effects */
.small-btn:hover, .small-btn2:hover, .small-btn3:hover {
  height: 557px;
}

/* SmallButton1 Specific Styles */
.small-btn {
  background: #417F4B;
}

.small-btn:hover {
  background: #3A7243;
}

.inner-rectangle2 {
  width: 155px;
  height: 68px;
  border-radius: 8px;
  background: #508859;
  position: absolute;
  top: 27px;
  right: 28px;
}

.text-top, .text-bottom {
  width: 114px;
  height: 19px;
  color: #FFF;
  text-align: right;
  font-family: 'EB Garamond', serif;
  font-size: 12px;
  position: absolute;
  top: 12px;
  left: 25px;
}

.text-bottom {
  width: 136px;
  height: 36px;
  color: #30343F;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 24px;
  position: absolute;
  top: 27px;
  left: 9px;
}

/* SmallButton2 Specific Styles */
.small-btn2 {
  background: #495166;
}

.small-btn2:hover {
  background: #3E4455;
}

.realstylecontainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 20%;
}

.realstyle {
  color: #FFF;
  text-align: center;
  font-family: Galgine;
  font-size: 37px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rec_container {
  width: 519px;
  height: 400px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  transition: top 0.3s ease;
}

.rec-img {
  width: 100%;
  height: auto;
}

/* SmallButton3 Specific Styles */
.small-btn3 {
  background: #000;
}

.small-btn3:hover {
  background: #000;
}

.inner-rectangle5 {
  width: 155px;
  height: 68px;
  border-radius: 8px;
  background: #0E0D0D;
  position: absolute;
  top: 27px;
  right: 28px;
}

.text-bottom5 {
  width: 155px;
  height: 68px;
  color: #FDFDFD;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 16px;
  position: absolute;
  top: 6px;
  left: 0px;
}

.sqrphil {
  width: 455px;
  height: 409px;
  position: absolute;
  overflow: hidden;
  border-radius: 11px 0px 20px 0px;
  bottom: 0px;
  right: 0px;
  transition: top 0.3s ease;
}

.phil-img {
  width: 100%;
  height: auto;
  transform: scale(1.4);
}

/* Shrink elements proportionally for 720x1280 display */
@media (max-width: 720px) and (max-height: 1280px) {
    .small-btn, .small-btn2, .small-btn3 {
      width: 299.2px; /* Shrink width by 0.5 */
      height: 274px; /* Shrink height by 0.5 */
    }
  
    .small-btn:hover, .small-btn2:hover, .small-btn3:hover {
      height: 278px; /* Adjust hover height */
    }
  
    .inner-rectangle2, .inner-rectangle5 {
      width: 77.5px; /* Shrink width by 0.5 */
      height: 34px; /* Shrink height by 0.5 */
      top: 13.5px; /* Adjust top position */
      right: 14px; /* Adjust right position */
    }
  
    .realstyle {
        font-size: 25px;
    }
    .realstylecontainer{
        height: 0%;
    }
    .text-bottom5 {
        width: 60px; /* Keep the same width */
        height: 12px; /* Keep the same height */
        font-size: 8px; /* Shrink font size */
        top: 5px; /* Adjust top position */
        left: 9px; /* Adjust left position */
      }
  
    .rec_container {
      width: 299.2px; /* Shrink width by 0.5 */
      height: 230px; /* Shrink height by 0.5 */
    }
    .sqrphil{
    width: 259.5px; /* Shrink width by 0.5 */
      height: 204.5px; /* Shrink height by 0.5 */
    }
  
    .rec-img, .phil-img {
      transform: scale(1.2); /* Maintain proportional scale */
    }
  }
  