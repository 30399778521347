.spotify-currently-playing {
  display: flex;
  align-items: center;
  padding: 10px;
}

.spotify-logo {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  margin-right: 20px;
  margin-left: 15px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  font-size: 18px;
  font-family: 'EB Garamond', serif;
  color: #A30000;
  margin-bottom: 15px;
}

.track-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.track-details {
  font-size: 22px;
  font-weight: bold;
  font-family: 'EB Garamond', serif;
  color: #A30000;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-link {
  color: #A30000;
  text-decoration: none;
}

.track-link:hover {
  text-decoration: underline;
}


@media (max-width: 720px) {
  .spotify-currently-playing {
  padding: 0px; /* Halved */
}

.spotify-logo {
  width: 25px; /* Halved and reduced by 12% from 50px */
  height: 25px; /* Halved from 50px */
  margin-right: 10px; /* Halved from 20px */
  margin-left: 10px; /* Halved from 15px */
}


.header {
  font-size: 9px; /* Halved from 18px */
  margin-bottom: -20px; /* Halved from 15px */
}

.track-details {
  font-size: 11px; /* Halved from 22px */
  max-width: 220px; /* Halved and reduced by 12% from 500px */
}

}