#centerpiece2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 744px;
    height: 297px;
    flex-shrink: 0;
    margin: 160px auto 0;
    text-align: center;
  }
  
  .MiscPages-description {
    font-family: 'EB Garamond', serif;
    font-size: 20px;
    color: #A30000;
    margin-top: 20px;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 80px auto;
    max-width: 1200px;
    width: 100%;
  }
  
  .misc-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: 10px; /* Adjust the gap between Misc content and button */
  }
  
  .misc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5d8cb;
    color: #A30000;
    text-decoration: none;
    font-family: 'EB Garamond', serif;
    font-size: 20px;
    border-radius: 15px;
    transition: transform 0.3s, background-color 0.3s;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
  }
  
  .misc-button:hover {
    background-color: #f8f4f1;
    transform: translateY(-10px);
  }
  
  .main-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }
  