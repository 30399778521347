.Aboutimgrect {
  position: relative;
  justify-content: center;
  top: 29px;
  display: flex;
  width: 100%;
}

.Aboutimgrects {
  width: 700px;
  height: 526px;
  border-radius: 48px;
  overflow: hidden;
  position: relative;
  left: 18px;
}

.Aboutimgrects img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
}

.Aboutimgrect2 {
  position: relative;
  justify-content: center;
  top: 29px;
  display: flex;
  width: 100%;
}

.Aboutimgrects2 {
  width: 700px;
  height: 526px;
  border-radius: 48px;
  overflow: hidden;
  position: relative;
  left: 26px;
}

.Aboutimgrects2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
}

.image-name-label {
  position: absolute;
  bottom: 40px;
  right: 20px;
  background-color: rgba(255, 246, 237, 0.7); 
  padding: 5px 10px;
  font-family: 'Galgine', sans-serif;
  font-size: 18px;
  border-radius: 10px;
  color: black;
}


@media (max-width: 1080px) {
  .Aboutimgrect {
  position: relative;
  justify-content: center;
  top: 29px;
  display: flex;
  width: 100%;
}

.Aboutimgrects {
  width: 350px;
  height: 263px;
  border-radius: 24px;
  left: 9px;
}

.Aboutimgrects2 {
  width: 350px;
  height: 263px;
  border-radius: 24px;
  left: 13px;
}

.image-name-label {
  bottom: 20px;
  right: 10px;
  padding: 2.5px 5px;
  font-size: 9px;
}
}