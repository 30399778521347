#centerpiece2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 744px;
    height: 297px;
    flex-shrink: 0;
    margin: 160px auto 0;
    text-align: center;
  }

  #centerpiece2 h1 {
  color: #A30000;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 96px;
  font-weight: 400;
}
  
  .projects-description {
    font-family: 'EB Garamond', serif;
    font-size: 30px;
    color: #A30000;
    margin-top: 20px;
  }
  
  .projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: 220px;
    gap: 20px;
    max-width: 1200px;
    margin: 80px auto;
  }
  
  .project-button, .project-button-long {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e5d8cb;
    color: #A30000;
    text-decoration: none;
    font-family: 'EB Garamond', serif;
    font-size: 20px;
    border-radius: 20px;
    transition: transform 0.3s, background-color 0.3s;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .project-button {
    height: 200px;
    grid-row: span 1;
  }
  
  .project-button-long {
    height: 450px;
    grid-row: span 2;
  }
  
  .project-button:hover, .project-button-long:hover {
    background-color: #f8f4f1;
    transform: translateY(-10px);
  }
 
    .main-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }
  