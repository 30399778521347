/* Main Button */
#centerpiece {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 744px;
  height: 297px;
  margin: 240px auto 0;
}

#centerpiece h1 {
  color: #A30000;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 96px;
  font-weight: 400;
}

#centerpiece1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10000px;
  height: 141px;
  flex-shrink: 0;
  margin: 70px;
}

#centerpiece1 h1 {
  color: #A30000;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 96px;
  font-weight: 400;
}

@font-face {
  font-family: 'Galgine';
  src: url('../fonts/Galgine.otf') format('opentype');
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 160px;
  gap: 60px;  /* Space between button rows */
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 60px; /* Spacing between Large and Small Buttons */
}

/* Set default width for small buttons */
.small-btn, .small-btn2, .small-btn3 {
  width: 519px;
}

/* Stack buttons on top of each other when zoomed in (or on smaller screens) */
@media (max-width: 1200px) {
  .button-row {
    flex-direction: column;
    gap: 20px; /* Adjust gap for vertical spacing */
  }

  .small-btn, .small-btn2, .small-btn3 {
    width: 680px; /* Match the width of large buttons */
  }
}

/* Additional Styles if needed */
.large-btn, .small-btn, .small-btn2, .large-btn2, .small-btn3, .large-btn3 {
  margin-bottom: 28px; /* Spacing between rows */
}

/* Shrink elements proportionally for 720x1280 display */
@media (max-width: 720pxx  ) {
  #centerpiece {
    width: 100%; /* Shrink width by 0.5 */
    height: 200px; /* Shrink height by 0.5 */
  }

  #centerpiece h1, #centerpiece1 h1 {
    font-size: 44px; /* Shrink font size by 0.5 */
  }

  #centerpiece1 {
    width: 50000px; /* Shrink width by 0.5 */
    height: 70.5px; /* Shrink height by 0.5 */
  }

  .button-container {
    margin: 80px; /* Shrink margin by 0.5 */
    gap: 30px; /* Adjust gap between button rows */
  }

  .button-row {
    gap: 30px; /* Adjust gap between Large and Small Buttons */
  }

  .small-btn, .small-btn2, .small-btn3 {
    width: 340px; /* Shrink width by 0.5 */
  }

  .large-btn, .small-btn, .small-btn2, .large-btn2, .small-btn3, .large-btn3 {
    margin-bottom: 20px; /* Adjust spacing between rows */
  }
  
}

