.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f2f2f2;
}

.not-found-container h1 {
  font-size: 4rem;
  color: #A30000;
}

.not-found-container p {
  font-size: 1.5rem;
  color: #333;
}

.back-home-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #A30000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-home-button:hover {
  background-color: #933739;
}
