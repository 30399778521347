.project-container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.0);
}

.text-container1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  padding-left: 20px;
  margin-left: 10px;
}

.text-container1 h1 {
  font-family: 'EB Garamond', serif;
  font-size: 30px;
  color: #A30000;
  margin-bottom: 0px;
}

.text-container1 p {
  font-family: 'EB Garamond', serif;
  font-size: 15px;
  color: #A30000;
}

.ProjectPhoto1 {
  margin-right: 20px; /* 20px away from the right wall */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjPhotoEllipse1 {
  width: 75px;
  height: 75px;
  border-radius: 50%; /* Circle shape */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAE0D5; /* Background color */
}

.projphoto1 {
  width: 100%;
  height: auto;
  object-fit: cover; /* Make sure the image fits well */
}
