.misc2-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #A30000;
  padding: 20px;
  border-radius: 20px;
  max-width: 100%;
  width: 1200px;
  margin-bottom: 10px;
  background-color: transparent;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.text-container h2 {
  text-align: left;
  font-family: 'EB Garamond', serif;
  font-size: 28px;
  margin-bottom: 10px;
}

.text-container p {
  width: 800px;
  text-align: left;
  font-family: 'EB Garamond', serif;
  font-size: 20px;
}

.miscPhoto1 {
  margin-right: 20px; /* 20px away from the right wall */
  display: flex;
  justify-content: center;
  align-items: center;
}

.miscPhotoEllipse1 {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Circle shape */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAE0D5; /* Background color */
}

.miscphoto1 {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image fits well */
}
