/* Common Button Styles */
.large-btn, .large-btn2, .large-btn3 {
  width: 680px;
  height: 548px;
  flex-shrink: 0;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease; /* For smooth hover */
  border: none;
  outline: none;
}

/* Hover Effects */
.large-btn:hover, .large-btn2:hover, .large-btn3:hover {
  height: 557px;
}

/* LargeButton1 Specific Styles */
.large-btn {
  background: #BC4749;
}

.large-btn:hover {
  background: #933739;
}

.inner-rectangle {
  width: 155px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #C26466;
  position: absolute;
  top: 27px;
  right: 28px;
}

.text-topL {
  width: 114px;
  height: 19px;
  color: #FFF;
  text-align: right;
  font-family: 'EB Garamond', serif;
  font-size: 12px;
  position: absolute;
  top: 12px;
  left: 25px;
}

.TextBottomL1 {
  width: 136px;
  height: 36px;
  color: #30343F;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 24px;
  position: absolute;
  top: 27px;
  left: 9px;
}

/* LargeButton2 Specific Styles */
.large-btn2 {
  background: #D5AC4E;
}

.large-btn2:hover {
  background: #AD8C3F;
}

.inner-rectangle3 {
  width: 155px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #D5B15C;
  position: absolute;
  top: 27px;
  right: 28px;
}

.text-top3 {
  width: 114px;
  height: 19px;
  color: #FFF;
  text-align: right;
  font-family: 'EB Garamond', serif;
  font-size: 12px;
  position: absolute;
  top: 12px;
  left: 25px;
}

.TextBottomL3 {
  width: 136px;
  height: 36px;
  color: #30343F;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 24px;
  position: absolute;
  top: 27px;
  left: 9px;
}

.image-container {
  width: 623px;
  height: 409px;
  overflow: hidden;
  position: absolute;
  border-radius: 11px 0px 20px 0px;
  bottom: 0;
  right: 0;
}

.sqr-img {
  width: 100%;
  height: auto;
  transform: scale(1.1);
}

/* LargeButton3 Specific Styles */
.large-btn3 {
  background: #FFF;
}

.inner-rectangle6 {
  width: 155px;
  height: 68px;
  border-radius: 8px;
  background: #D2D2D2;
  position: absolute;
  top: 27px;
  right: 28px;
}

.TextBottomL6 {
  width: 140px;
  height: 68px;
  color: #30343F;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 20px;
  position: absolute;
  top: 4px;
  left: 6px;
}

.image-container-left {
    width: 292px;
    height: 202px;
    position: absolute;
    left: 35%;
    top: 69%;
    transform: translate(-50%, -50%) rotate(37.142deg);
    transition: all 0.3s ease;
  }
  
  .image-container-right {
    width: 292px;
    height: 202px;
    position: absolute;
    left: 65%;
    top: 65%;
    transform: translate(-50%, -50%) rotate(-25.132deg);
    transition: all 0.3s ease;
  }

.img-diag-left {
  width: 100%;
  height: 100%;
}

.img-diag-right {
  width: 100%;
  height: 100%;
}

.large-btn3:hover .image-container-left {
    transform: translate(-60%, -90%) rotate(37.142deg);
  }
  
  .large-btn3:hover .image-container-right {
    transform: translate(-45%, -80%) rotate(-25.132deg);
  }

/* Shrink elements proportionally for 720x1280 display with 12% smaller width */
@media (max-width: 720px) and (max-height: 1280px) {
    .large-btn, .large-btn2, .large-btn3 {
      width: 299.2px; /* Original width was 340px, reduced by 12% */
      height: 274px; /* Keep the same height */
    }
  
    .large-btn:hover, .large-btn2:hover, .large-btn3:hover {
      height: 278.5px; /* Shrink hover height by 0.5 */
    }
  
    .inner-rectangle, .inner-rectangle3, .inner-rectangle6 {
      width: 77.5px; /* Keep the same width */
      height: 34px; /* Keep the same height */
      top: 13.5px; /* Adjust top position */
      right: 14px; /* Adjust right position */
    }
    
  
    .text-topL, .text-top3 {
      width: 57px; /* Keep the same width */
      height: 9.5px; /* Keep the same height */
      font-size: 6px; /* Shrink font size */
      top: 6px; /* Adjust top position */
      left: 12.5px; /* Adjust left position */
    }
  
    .TextBottomL1, .TextBottomL3 {
      width: 60px; /* Keep the same width */
      height: 12px; /* Keep the same height */
      font-size: 12px; /* Shrink font size */
      top: 12px; /* Adjust top position */
      left: 9px; /* Adjust left position */
    }

    .TextBottomL6 {
      width: 60px; /* Keep the same width */
      height: 12px; /* Keep the same height */
      font-size: 8px; /* Shrink font size */
      top: 5px; /* Adjust top position */
      left: 9px; /* Adjust left position */
    }
  
    .image-container {
    top: 97px;
    width: 270.5px; /* Shrink width by 0.5 */
    height: 204.5px; /* Shrink height by 0.5 */

    }
    .image-container-left, .image-container-right {
      width: 128.4px; /* Proportional to the reduced button width */
      height: 101px; /* Keep the same height */
    }
  
    .sqr-img, .img-diag-left, .img-diag-right {
      transform: scale(1.05); /* Maintain proportional scale */
    }
  
  }
  
  