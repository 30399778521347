.Phil-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 3px;
  max-width: 100%;
  width: 1200px;
  margin-bottom: 10px;
  background-color: transparent;
}

.Philtext-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.Philtext-container h2 {
  text-align: left;
  font-family: 'EB Garamond', serif;
  font-size: 28px;
  margin-bottom: 10px;
}

.Philtext-container p {
  width: 800px;
  text-align: left;
  font-family: 'EB Garamond', serif;
  font-size: 20px;
}

.PhilPhoto1 {
  margin-right: 20px; /* 20px away from the right wall */
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhilPhotoEllipse1 {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Circle shape */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAE0D5; /* Background color */
}

.Philphoto1 {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image fits well */
}
