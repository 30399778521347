#centerpiece2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 744px;
    height: 297px;
    flex-shrink: 0;
    margin: 160px auto 0;
    text-align: center;
    margin-bottom: 50px;
  }
  
 .projects-description-container {
    width: 90%;
    max-width: 1000px;
    font-size: 24px;
    margin: 20px auto; /* Creates space between the header and paragraph */
    padding: 20px;
    box-sizing: border-box;
}

  .main-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }

  .bottom-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px; /* Space between the buttons */
    flex-wrap: wrap;
  }
  
  /* Recruiters Button */
  .phil-nav-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 900px; /* Make the button wider */
    height: 130px; /* Keep height taller */
    font-size: 30px; /* Larger text size */
    padding: 10px;
    background-color: #fff; /* Transparent background */
    color: #0E0D0D; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #FFF; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .phil-nav-button:hover {
    background-color: #0E0D0D; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#0E0D0D;
  }
  