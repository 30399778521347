#centerpiece2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 744px;
    height: 297px;
    flex-shrink: 0;
    margin: 160px auto 0;
    text-align: center;
    margin-bottom: 50px;
  }
  
 .friends-description-container {
    width: 90%;
    max-width: 1000px;
    font-size: 40px;
    margin: 20px auto; /* Creates space between the header and paragraph */
    padding: 20px;
    box-sizing: border-box;
}
#centerpieceFriends {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  flex-shrink: 0;
  margin: 50px;
}

#centerpieceFriends h1 {
  color: #A30000;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 60px;
  font-weight: 400;
}

  .main-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }

  .bottom-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px; /* Space between the buttons */
    flex-wrap: wrap;
  }
  
  /* Friends Button */
  .contracts-nav-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 400px; /* Make the button wider */
    height: 130px; /* Keep height taller */
    font-size: 30px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color: #D5AC4E; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #D5AC4E; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .contracts-nav-button:hover {
    background-color: #3A7243; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#3A7243;
  }
  
  /* Recruiters Button */
  .philosophy-nav-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 400px; /* Make the button wider */
    height: 130px; /* Keep height taller */
    font-size: 30px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color: #D5AC4E; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #D5AC4E; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .philosophy-nav-button:hover {
    background-color: #0E0D0D; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#0E0D0D;
  }
  
/* Main container for fantasy images, centered and 1450px wide */
.fantasy-main-container {
    display: flex;
    justify-content: space-between;
    width: 1340px; /* Set container width */
    margin: 0 auto; /* Center the container */
    padding: 0px;
  }
  
  /* Individual fantasy item with color and size */
  .fantasy-item {
    font-family: 'Galgine', serif;
    color: #A30000;
    width: 420px;
    height: 730px;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Fantasy Team 1, 2, and 3 with different colors */
  .fantasy-team1 {
    color: #fff;
    background-color: #ff5757; /* Light Khaki */
  }
  
  .fantasy-team2 {
    background-color: #f99; /* Light Blue */
  }
  
  .fantasy-team3 {
    background-color: #f99; /* Light Pink */
  }
  
  /* Scrollable image box */
  .image-box-scrollable {
    width: 400px;
    height: 700px;
    overflow-y: scroll; /* Enable vertical scrolling */
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .image-box-scrollable img {
    width: 100%; /* Fit image width */
    height: auto;
  }
  
  /* Info rectangle with text */
.fantasy-second-container {
    display: flex;
    justify-content: space-between;
    width: 1400px; /* Set container width */
    height: 700px;
    margin: 0 auto; /* Center the container */
    padding: 0px;
  }

.info-rectangle {
  width: 1022px;
  height: 520px;
  background-color: #fff6ed;
  border-radius: 48px;
  margin-top: 40px;
  margin-left: 250px;
  margin-bottom: 0px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
}
  #FantasyPara {
    display: flex;
    flex-direction: row; /* Aligns child elements horizontally */
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 20px;
    height: 750px; /* Changed from fixed height */
    width: 100%;
  }

  /* Golf Image Container */
.golf-container {
  display: relative;
  justify-content: flex-end;
  margin: 0 auto;
  width: 1400px; /* Set container width */
  height: 750px;
  margin-top: 0px;
}
.golf-container2 {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 1400px; /* Set container width */
  height: 300px;
  margin-top: 0px;
}

.golf-image-box {
  display: flex;
  gap: 20px;
}

.golf-image-box img {
  width: 700px;
  height: 700px;
  border-radius: 20px;
}

/* Paragraph styling */
.golf-description {
  color: #A30000;
  font-family: "EB Garamond";
  font-size: 28px;
  line-height: normal;
  text-align: right;
  margin-left: 30px;
}

/* Main container for books */
.books-friends-container {
    display: flex;
    width: 1400px;
    height: 800px;
    margin: 0 auto;
  }
  
  /* Left 2/3rd container */
  .books-friends-left {
    width: 66.66%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Right 1/3rd container */
  .books-friends-right {
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll; /* Enable scrolling for the right side */
  }
  
  /* Left rectangle with red border */
  .books-friends-rect-left {
    width: 90%;
    height: 700px;
    background-color: #fff6ed;
    border: 2px solid #A30000; /* Red border */
    border-radius: 10px;
    padding: 20px;
  }
  
  /* Right rectangle with red border */
  .books-friends-rect-right {
    width: 90%;
    height: 700px;
    background-color: #fff6ed;
    border: 2px solid #A30000; /* Red border */
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto; /* Scrollable content */
  }
  .books-friends-header {
  font-size: 35px;
  color: #A30000;
  font-family: "EB Garamond";
  text-align: center;
}
  /* Buttons in right container */
  .books-friends-button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .books-friends-description {
  color: #A30000;
  font-family: "EB Garamond";
  font-size: 28px;
  line-height: normal;
  text-align: left;
  padding: 10px;
}
  
  .books-friends-button {
    width: 90%;
    height: 100px;
    padding: 20px;
    background-color: #A30000;
    font-family: 'Galgine', serif;
    font-size: 28px;
    color: white;
    text-align: left;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .books-friends-button:hover {
    background-color: #933739;
  }
  
  /* Star icon positioning */
.star-container {
  display: flex;
  gap: 5px;
  position: flex;
}

.star-icon {
  object-fit: cover;
  width: 25px;
  height: 25px;
}
.half-star-icon {
    object-fit: cover;
    width: 12.5px;
    height: 29px;
}


/* Main container for bars and reviews */
.bars-friends-container {
    display: flex;
    width: 1400px;
    height: 800px;
    margin: 0 auto;
  }
  
  /* Left 50% container (Non-scrollable rectangle) */
  .bars-friends-left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll; /* Enable scrolling for the right side */
  }
  
  /* Right 50% container (Scrollable buttons) */
  .bars-friends-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll; /* Enable scrolling */
  }
  
  /* Rectangles with red borders */
  .bars-friends-rect-left, .bars-friends-rect-right {
    width: 90%;
    height: 700px;
    background-color: #fff6ed;
    border: 2px solid #A30000; /* Red border */
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto; /* Scrollable content */
  }
  
  
  /* Scrollable buttons inside the left rectangle */
  .bars-friends-button-container {
    height: 100%;
    overflow-y: auto; /* Enable scrolling inside the rectangle */
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .bars-friends-button {
    width: 90%;
    height: 120px;
    padding: 20px;
    background-color: #A30000;
    font-family: 'Galgine', serif;
    font-size: 28px;
    color: white;
    text-align: left;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .bars-friends-button:hover {
    background-color: #933739;
  }
  
  /* Popup Iframe */
/* Popup iframe */
.iframe-popup {
    position: absolute;
    width: 1000px;
    height: 600px;
    background-color: white;
    border: 2px solid #A30000;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .iframe-container {
    width: 98%;
    height: 100%;
    padding: 10px;
  }
  
  .iframe-container iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .info-rectangle2 {
  width: 1022px;
  height: 700px;
  background-color: #fff6ed;
  border-radius: 48px;
  margin-top: 40px;
  margin-left: 180px;
  margin-bottom: 0px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
}

  .AboutFriends-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 300px; /* Make the button wider */
    height: 80px; /* Keep height taller */
    font-size: 20px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color:  #A30000; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #A30000; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  

.AboutFriends-button:hover {
    background-color: #D5AC4E; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#D5AC4E;
}

.fantasy-third-container {
    display: flex;
    justify-content: space-between;
    width: 1400px; /* Set container width */
    height: 800px;
    margin: 0 auto; /* Center the container */
    padding: 0px;
  }

  .chess-section {
  margin-top: 50px;
  text-align: center;
}
.chess-image {
  width: 1400px;
  height: 800px;
  border-radius: 10px;
}

.chess-container2 {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 1400px; /* Set container width */
  height: 300px;
  margin-top: 0px;
  text-align: left;
}
.chess-description {
  color: #A30000;
  font-family: "EB Garamond";
  font-size: 28px;
  line-height: normal;
  text-align: left;
  margin-left: 30px;
}

.BBQPara4, .BBQPara5, .BBQPara6s{
    position: absolute;
    left: 78px;
    width: 833px;
}
.BBQPara4{
    top: 48px;
    height: 192px;
}
.BBQPara5{
    top: 310px;
    height: 216px;
}