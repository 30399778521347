.bottom-bar {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 404px;
  background: #EAE0D5;
  color: #EAE0D5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 20px; /* Space between the groups */
  z-index: 1; /* Ensure the bottom bar itself is at a lower z-index */
}

.group-left,
.group-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 600px; /* Fixed width for the groups */
  background: #EAE0D5; /* Background color for the groups */
  border-radius: 10px; /* Border radius for the groups */
  padding: 20px; /* Padding inside the groups */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.group-left {
  align-items: flex-start;
}

.group-right {
  align-items: flex-start;
}

.columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.column-title {
  font-size: 24px;
  font-weight: 700;
  color: #A30000;
  font-family: 'Galgine', sans-serif; /* Add font-family */
  margin-bottom: -3px;
}

.inline-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inline-container .name1 {
  margin-top: -2px; /* Adjust as needed */
}

.inline-container .ellipse5,
.inline-container .button11 {
  margin-top: 2px; /* Adjust as needed */
}

.spotifyrect1 {
  width: 533px;
  height: 95px;
  border-radius: 26px;
  background: #FFF6ED;
}

.bottomtext1 {
  color: #A30000;
  font-family: 'Galgine', sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.name1 {
  width: 136px;
  height: 29px;
}

.ellipse5 {
  width: 7px;
  height: 7px;
  background: #A30000;
  border-radius: 50%;
}

.button11 {
  border: none;
  background: none;
  cursor: pointer;
  position: relative; /* Ensure the overlay is positioned relative to the button */
}

.button-text1 {
  width: 109px;
  height: 29px;
}

.overlay {
  width: 646px;
  height: 226px;
  border-radius: 34px;
  background: rgba(255, 246, 237, 0.8);
  position: absolute;
  bottom: 100%; /* Position the overlay above the button */
  left: 250%; /* Move the overlay slightly to the right */
  transform: translateX(-50%) translateY(-10px); /* Center the overlay above the button */
  display: flex; /* Flex display for columns */
  z-index: 10; /* Ensure the overlay is above everything */
  opacity: 0; /* Start with the overlay hidden */
  transition: opacity 0.5s ease; /* Add fade-in transition */
}

.button11:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.overlay-left, .overlay-right {
  display: flex;
  flex-direction: column;
  width: 50%; /* Each column takes half the width */
  padding: 10px; /* Add some padding */
}

.overlay-text,
.overlay-text-large,
.overlay-paragraph {
  color: #A30000;
  font-family: "EB Garamond";
  font-size: 28px;
  line-height: normal;
  text-align: left;
}

.overlay-paragraph {
  width: auto; /* Adjust width as needed */
  height: 159px;
  text-align: left; 
}

.overtext3,
.overtext2,
.overtext1,
.overlay-text-large {
    margin: 5px; /* Remove margin to eliminate gap */
}

.elsewhereB {
  display: flex;
  width: 123px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.to-the-rightB {
  display: flex;
  width: 123px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.bottom-button {
  border: none;
  background: none;
  width: 201px;
  height: 32px;
  text-align: left;
  color: #A30000;
  font-family: 'Galgine', sans-serif; /* Add font-family */
  font-size: 20px;
  font-weight: 400;
}

.email, .phone {
  width: 219px;
  height: 32px;
  text-align: left;
  color: #A30000;
  font-family: 'Galgine', sans-serif; /* Add font-family */
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 1280px) {
  .bottom-bar {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .group-left,
  .group-right {
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .group-left,
  .group-right {
  gap: 10px;
}
.spotifyrect1 {
  width: 234px;
  height: 47px;
  border-radius: 216px;
  background: #FFF6ED;
}

.bottomtext1 {
  color: #A30000;
  font-family: 'Galgine', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.name1 {
  width: 60px;
  height: 14px;
}

.ellipse5 {
  width: 3.5px;
  height: 3.5px;
  background: #A30000;
  border-radius: 50%;
}

.button-text1 {
  width: 48px;
  height: 14px;
}
.overlay {
  width: 284.64px; /* Halved and reduced by 12% from 646px */
  height: 99.44px; /* Halved and reduced by 12% from 226px */
  border-radius: 14.96px; /* Halved and reduced by 12% from 34px */

}

.overlay-left, .overlay-right {
  padding: 4.4px; /* Halved and reduced by 12% from 10px */
}

.overlay-text,
.overlay-text-large,
.overlay-paragraph {
  font-size: 12.32px; /* Halved and reduced by 12% from 28px */
}

.overlay-paragraph {
  height: 69.96px; /* Halved and reduced by 12% from 159px */
}

.elsewhereB {
  width: 54.12px; /* Halved and reduced by 12% from 123px */
  height: 14.08px; /* Halved and reduced by 12% from 32px */
}

.to-the-rightB {
  width: 54.12px; /* Halved and reduced by 12% from 123px */
  height: 14.08px; /* Halved and reduced by 12% from 32px */
}

.bottom-button {
  width: 88.44px; /* Halved and reduced by 12% from 201px */
  height: 14.08px; /* Halved and reduced by 12% from 32px */
  font-size: 8.8px; /* Halved and reduced by 12% from 20px */
}

.email, .phone {
  width: 96.36px; /* Halved and reduced by 12% from 219px */
  height: 14.08px; /* Halved and reduced by 12% from 32px */
  font-size: 8.8px; /* Halved and reduced by 12% from 20px */
}
}
