.project-container3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.0);
}

.text-container3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Align the text to the left */
  padding: 20px;
}

.text-container3 h1 {
  font-family: 'EB Garamond', serif;
  font-size: 35px;
  color: #A30000;
  margin-bottom: 10px;
}

.text-container3 p {
  font-family: 'EB Garamond', serif;
  font-size: 20px;
  color: #A30000;
}

.ProjectPhoto3 {
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align the image to the bottom */
  margin-top: auto; /* Push the image to the bottom */
}

.ProjPhotoRectangle3 {
  width: 290px;
  height: 227px;
  background-color: #EAE0D5; /* Background color for the rectangle */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: fill;
}

.projphoto3 {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the square image fits inside the rectangle without distortion */
}



.ProjectPhoto1 {
  margin-right: 20px; /* 20px away from the right wall */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjPhotoEllipse1 {
  width: 75px;
  height: 75px;
  border-radius: 50%; /* Circle shape */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAE0D5; /* Background color */
}

.projphoto1 {
  width: 100%;
  height: auto;
  object-fit: cover; /* Make sure the image fits well */
}
