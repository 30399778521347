#centerpieceAbout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 744px;
  height: 297px;
  flex-shrink: 0;
  margin: 160px auto 0;
}

#centerpieceAbout h1 {
  color: #A30000;
  text-align: center;
  font-family: 'Galgine', serif;
  font-size: 96px;
  font-weight: 400;
}

/* Add this below your existing CSS */
.content-container {
  background-color: rgba(255, 255, 255, 0.0); /* Transparent background */
  box-sizing: border-box;
  max-width: 1600px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
  width: 100%;
}




#AboutMeStartcontainer {
    display: flex;
    flex-direction: row; /* Aligns child elements horizontally */
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 150px;
    height: 413px; /* Changed from fixed height */
    width: 100%;
  }

  .aboutoimage-container, .aboutrectspotify, .AboutPara1, .AboutPara2 {
    position: absolute;
  }

.aboutoimage-container {
    top: 0;
    left: 0;
    width: 376px;
    height: 333px;
  flex-shrink: 0;
  border-radius: 150px 150px 0px 0px;
  background: lightgray;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 150px 150px 0px 0px;
}

.aboutrectspotify {
    width: 376px;
    height: 80px;
    flex-shrink: 0;
    background: #710000;
    top: 333px;  
    left: 0;
  }

  .aboutoimage-container, .aboutrectspotify {
    left: 0px;
  }
  

  .AboutPara1{
    width: 683px; 
    height: 177px;
    left: 550px; 
  }
  .AboutPara2{
    width: 678px; 
    height: 166px;
    left: 550px;
    top: 238px;
  }

#AboutMeResume {
    display: flex;
    flex-direction: row; /* Aligns child elements horizontally */
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 5px;
    height: 550px; /* Changed from fixed height */
    width: 100%;
    left: -100px;
  }

  #AboutMeColab {
    display: flex;
    flex-direction: row; /* Aligns child elements horizontally */
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 5px;
    height: 313px; /* Changed from fixed height */
    width: 100%;
    left: -100px;
  }

  #AboutMePara {
    display: flex;
    flex-direction: row; /* Aligns child elements horizontally */
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 20px;
    height: 721px; /* Changed from fixed height */
    width: 100%;
    left: -100px;
  }

  #AboutMePhotos {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 5px;
    height: 588px; /* Changed from fixed height */
    width: 1444px;
  }

.ResumeRect1, .ResumeRect2, .ResumeRect3, .ResumeRect4, .ResumeRect6 {
    border-radius: 61px;
    background-color: #710000;
    height: 93px;
    position:absolute;
}

.ResumeRect1{
    width:1000px;
    top: 151px;
    left: 51px;
}

.ResumeRect2{
    width: 668px;
    top: 252px;
    left: 383px;
}

.ResumeRect3{
    width: 360px;
    top: 353px;
    left: 51px;
}

.ResumeRect4{
    width: 321px;
    top: 453px;
    left: 563px;
}

.ResumeRect6{
  width: 408px;
  top: 51px;
  left: 970px;
}
.ResumeRect5{
    width: 1022px;
    height: 706px;
    background-color: #fff6ed;
    border-radius: 48px;
    position: absolute;
    left: 235px;
}
.AboutPara3{
    position: absolute;
    color: #A30000;
  font-family: 'Galgine', serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
    width: 955px; 
    height: 245px;
    left: 420px;
    top: 48px;
    font-size: 48px;
}
.AboutPara4, .AboutPara5, .AboutPara6{
    position: absolute;
    left: 78px;
    width: 833px;
}
.AboutPara4{
    top: 47px;
    height: 192px;
}
.AboutPara5{
    top: 250px;
    height: 216px;
}

.AboutPara6{
    top: 490px;
    height: 113px;
}

.ResumPara1{
    position: absolute;
    top: 12px;
    height: 37px;
    left: 39px;
}
.ResumPara2{
    position: absolute;
    top: 43px;
    height: 37px;
    left: 39px;
}
.ResumNum{
    position: absolute;
    top: 30px;
    right: 36px;
    height: 43px;
    width: 88px;
}
  .AboutMeTextLarge {
  color: #A30000;
  font-family: 'Galgine', serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.AboutMeTextSmall {
  color: #710000;
  font-family: 'EB Garamond', serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.AboutMeWhiteLarge {
  color: #EAE0D5;
  font-family: 'EB Garamond', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AboutMeGreyLarge {
  color: #A67E7E;
  font-family: 'EB Garamond', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AboutMeWhiteSmall {
  color: #EAE0D5;
  font-family: 'EB Garamond', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AboutMeGreySmall {
  color: #A67E7E;
  font-family: 'EB Garamond', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AboutMeWhiteNum {
  color: #fff;
  font-family: 'Galgine', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AboutFriends-button-container {
    display: flex;
    justify-content: center;
    margin-left: 600px;
    margin-top: -10px;
    margin-bottom: 50px; /* Add margin to push it above the bottom bar */
  }
  
  .AboutFriends-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Galgine', serif;
    width: 300px; /* Make the button wider */
    height: 80px; /* Keep height taller */
    font-size: 20px; /* Larger text size */
    padding: 10px;
    background-color: transparent; /* Transparent background */
    color:  #A30000; /* Text color */
    text-align: center;
    text-decoration: none;
    border: 2px solid #A30000; /* Add a border */
    border-radius: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  

.AboutFriends-button:hover {
    background-color: #D5AC4E; /* Change background on hover */
    color: white; /* Text color change on hover */
    border:#D5AC4E;
}

@media (max-width: 1080px) {
  #centerpieceAbout {
    width: 100%;
    height: 297px; /* Scaled down proportionally from 297px */
    margin: 0px auto 0;
  }

  #centerpieceAbout h1 {
    font-size: 49px; /* Scaled down proportionally from 96px */
  }
  #AboutMeStartcontainer {
    margin-top: 50px;
    height: 780px; /* Changed from fixed height */
    width: 100%;
  }
  .aboutoimage-container {
    width: 376px; /* Scaled down proportionally from 376px */
    height: 333px; /* Scaled down proportionally from 333px */
  }

  .aboutrectspotify {
    width: 376px; /* Scaled down proportionally from 376px */
    height: 80px; /* Scaled down proportionally from 80px */
    top: 333px; /* Scaled down proportionally from 333px */
  }

    .AboutMeTextLarge {
  font-size: 24px;
  }
  .AboutMeTextSmall {
  font-size: 18px;
}

  .AboutPara1{
    width: 300px; 
    height: 120px;
    left: 20px; 
    top: 420px;
  }
  .AboutPara2{
    width: 100%; 
    height: 90px;
    left: 10px;
    top: 690px;
  }
  #AboutMeResume {
    margin-top: 5px;
    height: 400px; /* Changed from fixed height */
    width: 100%;
    left: 0px;
  }

  .ResumeRect1, .ResumeRect2, .ResumeRect3, .ResumeRect4, .ResumeRect6 {
    border-radius: 61px;
    background-color: #710000;
    height: 67px;
    width: 100%;
    left: 0px;
    position:absolute;
    align-items: center;
}
.ResumeRect1{
    top: 121px;
}

.ResumeRect2{
    top: 191px;
}

.ResumeRect3{
    top: 261px;
}

.ResumeRect4{
    top: 331px;
}

.ResumeRect6{
  top: 51px;
}
.AboutMeWhiteLarge, .AboutMeGreyLarge, .AboutMeWhiteNum  {
  font-size: 17px;
}
  #AboutMeColab {
    margin-top: 5px;
    height: 313px; /* Changed from fixed height */
    width: 100%;
    left: -410px;
  }
  .AboutPara3{
    width: 100%; 
    height: 245px;
    left: 420px;
    top: 48px;
    font-size: 24px;
}


  #AboutMePara {
    margin-top: 0px;
    height: 300px; /* Changed from fixed height */
    width: 100%;
    left: 0px;
  }
  .ResumeRect5{
    width: 400px;
    height: 300px;
    border-radius: 20px;
    left: 0px;
}
.AboutMeTextSmall {
  font-size: 12px;
}
.AboutPara4, .AboutPara5, .AboutPara6{
    left: 20px;
    width: 400px;
}
.AboutPara4{
    top: 20px;
    height: 192px;
}
.AboutPara5{
    top: 120px;
    height: 216px;
}

.AboutPara6{
    top: 220px;
    height: 113px;
}
.AboutFriends-button-container {
    display: flex;
    justify-content: center;
    margin-left: 0px;
    margin-top: -10px;
    margin-bottom: 10px; /* Add margin to push it above the bottom bar */
  }

  
}

